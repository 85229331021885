'use strict';

$(function() {
	function setup() {
        Fancybox.bind("[data-fancybox]", {});

        const swiper = new Swiper('.swiper', {
            autoplay: {
                delay: 5000,
            },

            // Optional parameters
            direction: 'horizontal',
            loop: true,

            autoScrollOffset: 0,
            multipleActiveThumbs: true,
            slideThumbActiveClas: 'swiper-slide-thumb-active',
            thumbsContainerClass: 'swiper-thumbs',
          
            // If we need pagination
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
            },
          
            // Navigation arrows
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            },
          });

        const visualEventImage = $('#visual-event-image');
        const headerHeight = $('header').outerHeight();
        const documentHeight = $(document).outerHeight();
        visualEventImage.height(documentHeight - headerHeight - 40);

        const hideButton = $('#hide_info_ticketing');
        if (hideButton){
          hideButton.on('click', function() {
            console.log('click');
            $('#info_ticketing').hasClass('hidden') ? hideButton.text('Masquer les informations') : hideButton.text('Afficher les infomations');
            $('#info_ticketing').toggleClass('hidden');
          });
        }
	}
    setup();
});
